import { render, staticRenderFns } from "./sidebarClasses.vue?vue&type=template&id=6510cb5e&scoped=true&"
import script from "./sidebarClasses.vue?vue&type=script&lang=js&"
export * from "./sidebarClasses.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6510cb5e",
  null
  
)

export default component.exports